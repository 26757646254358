/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.logoblock {
  right: 4.21875%;
}
.moodcontent {
  left: 16.40625%;
  width: 47.34375%;
}
.cb-page-layout2 .moodcontent {
  justify-content: flex-start;
  width: 66.66666667%;
  padding-left: 16.71875%;
  padding-right: 3.125%;
  padding-top: 150px;
}
.downlink,
.cb-goto-page-next {
  bottom: 50px;
  width: 123px;
  height: 60px;
}
.section--footer .logo {
  width: 270px;
  height: 99px;
}
.section--footer .cols.cols--logos {
  float: right;
  width: 66.66666667%;
}
.section--footer .cols.cols--logos .col .title {
  padding-left: 0;
}
.section--footer .cols.cols--logos .col .logos .logo-container {
  box-sizing: border-box;
  padding: 20px 40px 20px 0;
}
h1 {
  font-size: 70px;
  font-size: 7rem;
  line-height: 1.2;
}
.cb-page-layout2 h1 {
  font-size: 50px;
  font-size: 5rem;
  line-height: 1.2;
}
.cb-page-layout3 h1,
.cb-page-layout4 h1,
.cb-page-layout5 h1,
.cb-page-layout6 h1,
h2 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.2;
}
.loud {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.20833333;
}
.cb-page-layout1 .area.main .unit.pure {
  padding: 50px 11.71875%;
}
.cb-page-layout1 .area.side .unit.pure {
  padding: 50px 7.8125vw;
}
.cb-page-layout1 .area.base .unit {
  min-height: 360px;
}
.cb-page-layout1 .area.base .unit.pure {
  padding: 50px;
}
.cb-page-layout3 .cb-page-title {
  padding: 50px 7.02576112% 0;
}
.cb-page-layout3 .area.main,
.cb-page-layout3 .area.side {
  width: 100%;
}
.cb-page-layout3 .area.main > .unit,
.cb-page-layout3 .area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout3 .area.main .foot,
.cb-page-layout3 .area.side .foot {
  margin-right: 7.02576112%;
  margin-left: 7.02576112%;
}
.cb-page-layout3 .area.main .part,
.cb-page-layout3 .area.side .part {
  margin-right: 7.02576112%;
  margin-left: 7.02576112%;
  width: 85.94847775%;
}
.cb-page-layout3 .area.main .tiny,
.cb-page-layout3 .area.side .tiny {
  width: 35.94847775%;
}
.cb-page-layout3 .area.main > .slim,
.cb-page-layout3 .area.side > .slim {
  width: 50%;
}
.cb-page-layout3 .area.main > .slim .foot,
.cb-page-layout3 .area.side > .slim .foot,
.cb-page-layout3 .area.main > .slim .part,
.cb-page-layout3 .area.side > .slim .part {
  margin-right: 14.05152225%;
  margin-left: 14.05152225%;
}
.cb-page-layout3 .area.main > .slim .part,
.cb-page-layout3 .area.side > .slim .part {
  width: 71.8969555%;
}
.cb-page-layout3 .area.main > .slim .tiny,
.cb-page-layout3 .area.side > .slim .tiny {
  width: 71.8969555%;
}
.cb-page-layout3 .area.main .unit.pure,
.cb-page-layout3 .area.side .unit.pure,
.cb-page-layout3 .area.base .unit.pure {
  padding: 35px 0 20px;
}
.cb-page-layout3 .area.main .unit.pure {
  padding-top: 15px;
}
.cb-page-layout4 .cb-page-title {
  padding: 50px 4.68384075% 0;
}
.cb-page-layout4 .area.main {
  width: 100%;
}
.cb-page-layout4 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout4 .area.main .foot {
  margin-right: 4.68384075%;
  margin-left: 4.68384075%;
}
.cb-page-layout4 .area.main .part {
  margin-right: 4.68384075%;
  margin-left: 4.68384075%;
  width: 90.6323185%;
}
.cb-page-layout4 .area.main .tiny {
  width: 23.96565183%;
}
.cb-page-layout4 .area.main > .slim {
  width: 33.33333333%;
}
.cb-page-layout4 .area.main > .slim .foot,
.cb-page-layout4 .area.main > .slim .part {
  margin-right: 14.05152225%;
  margin-left: 14.05152225%;
}
.cb-page-layout4 .area.main > .slim .part {
  width: 71.8969555%;
}
.cb-page-layout4 .area.main > .slim .tiny {
  width: 21.8969555%;
}
.cb-page-layout5 .cb-page-title {
  padding: 50px 2.34192037% 0;
}
.cb-page-layout5 .area.main {
  width: 100%;
}
.cb-page-layout5 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout5 .area.main .foot {
  margin-right: 2.34192037%;
  margin-left: 2.34192037%;
}
.cb-page-layout5 .area.main .part {
  margin-right: 2.34192037%;
  margin-left: 2.34192037%;
  width: 95.31615925%;
}
.cb-page-layout5 .area.main .tiny {
  width: 45.31615925%;
}
.cb-page-layout5 .area.main > .slim {
  width: 50%;
}
.cb-page-layout5 .area.main > .slim .foot,
.cb-page-layout5 .area.main > .slim .part {
  margin-right: 4.68384075%;
  margin-left: 4.68384075%;
}
.cb-page-layout5 .area.main > .slim .part {
  width: 90.6323185%;
}
.cb-page-layout5 .area.main > .slim .tiny {
  width: 40.6323185%;
}
.cb-page-layout5 .area.main .unit.seam:before {
  left: 2.34192037%;
}
.cb-page-layout5 .area.main .unit.seam.slim:before {
  left: 4.68384075%;
}
.cb-page-layout5 .area.main .unit.flat.wide {
  width: 50%;
}
.cb-page-layout5 .area.main .unit.flat.wide .part {
  width: 90.6323185%;
  margin-left: 4.68384075%;
  margin-right: 4.68384075%;
}
.cb-page-layout5 .area.main .unit.flat.wide .part.tiny {
  width: 40.6323185%;
}
.cb-page-layout5 .area.main .unit.flat.slim {
  width: 25%;
}
.cb-page-layout5 .area.main .unit.flat.slim .part {
  width: 81.264637%;
  margin-left: 9.3676815%;
  margin-right: 9.3676815%;
}
.cb-page-layout5 .area.main .unit.flat.slim .part.tiny {
  width: 31.264637%;
}
.cb-page-layout6 .container--main {
  padding: 50px 7.8125%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp,
.recommend .disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */